export default function groupObj({search_params}) {
    
    if(search_params.includes('isdn')) {
        return '1isdn';
    } else if(search_params.includes('image')) {
        return '2photo'
    } else if(search_params.includes('email')) {
        return '3GoogleAccountSearch'
    } else if(search_params.includes('username')) {
        return '4NicknameSearch'
    } else if(search_params.includes('car_plate_num')) {
        return '5Auto'
    } else if (search_params.includes('social_url')){
        return '6InternetArchive'
    } else if (search_params.includes('point')) {
        return '7GeoSearch'
    }
}